import AddToCart from '~/themes/base/components/cart/AddToCart'

export default {
  mixins: [AddToCart],
  methods: {
    async addToCart(variantId) {
      if (this.$store.state.globals.cartConfiguration.shopping_disabled) {
        notifications.warning(this.$store.state.globals.cartConfiguration.shopping_disabled_text)
        return
      }
      this.startLoading()
      const data = await this.$store.dispatch('cart/ADD_TO_CART', {
        id: variantId,
        quantity: this.$store.state.cart.renting.rentDuration,
        newLine: true,
        forCredits: this.forCredits,
      })
      if (data && data.addedItem) {
        this.addedItem = data.addedItem
        this.changedAmount = data.changedAmount
        this.$refs.addToCartModalChooseVariant && this.$refs.addToCartModalChooseVariant.hide()
        this.openAddToCartModalItemAdded()
      }
      this.stopLoading()
    },
  },
}
