var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Container',{staticClass:"HeaderMain",attrs:{"full-viewport-width-wrapper":_vm.$themeSettings.components.HeaderMain.fullViewportWidthWrapper}},[_c('div',{staticClass:"HeaderMain__rowWrapper"},[_c('div',{staticClass:"row flex-nowrap align-items-stretch justify-content-between print-justify-content-between"},[_c('div',{staticClass:"col-8 d-md-flex col-md-6 align-items-md-center",attrs:{"role":"banner"}},[_c('nuxt-link',{attrs:{"to":_vm.localePath({name: 'index'}),"title":_vm.$t('HeaderMain.logoTitle', { website: this.$t('globals.website') })}},[_c('Logo',{ref:"logo"})],1)],1),_c('div',{staticClass:"d-flex col-16 d-md-none align-items-center justify-content-end d-print-none"},[_c('div',{staticClass:"HeaderMain__iconMobile"},[_c('a',{attrs:{"href":_vm.localePath({name: 'search'})},on:{"click":_vm.toggleSearch}},[_c('svgicon',{staticClass:"svg-fill",attrs:{"icon":require('@icon/search.svg?raw')}})],1)]),_c('div',{staticClass:"HeaderMain__iconMobile"},[_c('nuxt-link',{attrs:{"to":_vm.localePath({name: _vm.userLink})}},[_c('svgicon',{staticClass:"svg-fill",attrs:{"icon":require('@icon/person.svg?raw')}})],1)],1),_c('div',{staticClass:"HeaderMain__iconMobile HeaderMain__iconMobile--cartInfo"},[_c('CartInfo')],1),_c('div',{staticClass:"HeaderMain__hamburgerWrapper",class:{
            'HeaderMain__hamburgerWrapper--compact':
              _vm.$contentSettings.functions.compare
              || _vm.$contentSettings.functions.wishList
          }},[_c('button',{staticClass:"hamburger hamburger--squeeze",class:{'is-active': _vm.open},attrs:{"id":"MainMenuMobileHamburger","aria-label":[_vm.open ? _vm.$t('HeaderMain.mobileMenuButton.ariaLabelClose') : _vm.$t('HeaderMain.mobileMenuButton.ariaLabelOpen')],"aria-expanded":[_vm.open ? 'true' : 'false'],"aria-controls":_vm.mainMenuMobileAriaControls,"type":"button"},on:{"click":_vm.toggleMobileMenu}},[_c('span',{staticClass:"hamburger-box"},[_c('span',{staticClass:"hamburger-inner"})]),_c('small',{staticClass:"HeaderMain__hamburgerButtonText"},[_vm._v(" "+_vm._s(_vm.$t('HeaderMain.mobileMenuButton.text'))+" ")])])])]),_c('div',{staticClass:"d-none d-md-flex col-md-11 col-lg-7 align-items-sm-center d-print-none",class:{
          'offset-md-1 offset-xl-0':
            !(_vm.$contentSettings.functions.compare
              || _vm.$contentSettings.functions.wishList)
        }},[_c('SearchInput')],1),(_vm.$themeSettings.components.HeaderMain.showContentColumn)?_c('div',{staticClass:"d-none col-lg-6 col-xl-7 d-print-none",class:`d-${_vm.headerMainHookBreakpointVisibility}-block`},[_c('HeaderMainHook')],1):_vm._e(),_c('div',{staticClass:"d-none d-md-flex align-items-md-stretch justify-content-md-end d-print-none",class:[
          _vm.$contentSettings.functions.compare
            || _vm.$contentSettings.functions.wishList
            ? 'col-md-auto'
            : 'col-md-6 col-lg-auto'
        ]},[_c('CartInfo',{attrs:{"id":"CartInfo"}}),(false && _vm.$store.state.cart.isLoaded && _vm.$store.state.cart.cartData && _vm.$store.state.cart.cartData.items && _vm.$store.state.cart.cartData.items.length > 0)?_c('Dropdown',{attrs:{"options":{ placement: 'bottom-end' },"trigger":"CartInfo"}},[_c('CartInfoDropDownContent')],1):_vm._e()],1)]),_c(_vm.mainMenuMobileComponent,{ref:"MainMenuMobile",tag:"component"})],1),(_vm.$themeSettings.components.HeaderMain.mobileSearchAutocomplete)?_c('HeaderSearch'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }