import { notifications } from '~/app/notifications'

export default {
  props: {
    buttonMinusLabel: {
      type: String,
      default() {
        return this.$t('AppNumber.button.minus.label')
      },
    },
    buttonPlusLabel: {
      type: String,
      default() {
        return this.$t('AppNumber.button.plus.label')
      },
    },
    initial: {
      type: Number,
      default: 1,
    },
    inputLabel: {
      type: String,
      default() {
        return this.$t('AppNumber.input.label')
      },
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    step: {
      type: Number,
      default: 1,
    },
    unit: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: this.initial,
    }
  },
  watch: {
    value() {
      if (this.max && this.value > this.max) {
        this.value = this.max
        notifications.info(
          this.$options.filters.prepositions(this.$t('AppNumber.maxAmountReached', { maxAmount: this.max })),
        )
      }
    },
  },
  methods: {
    change(e) {
      let value = Number(e.target.value)
      if (Number.isNaN(value)) {
        value = this.min
      }
      if (value > this.max) value = this.max
      if (value < this.min) value = this.min // Fix in case of overstep
      this.update(value)
    },
    focus() {
      // Removing value and putting it back into input after setting focus on it will move cursor after input's value.
      const value = this.value
      this.value = ''
      setTimeout(() => {
        this.$refs.input.focus()
        this.$nextTick(() => {
          this.value = value
        })
      }, 1)
    },
    plus() {
      let value = this.value
      if (value < this.max) value = Number(value) + Number(this.step)
      if (value > this.max) value = this.max // Fix in case of overstep
      this.update(value)
    },
    minus() {
      let value = this.value
      if (value > this.min) value = Number(value) - Number(this.step)
      if (value < this.min) value = this.min // Fix in case of overstep
      this.update(value)
    },
    update(value) {
      this.value = value
      this.$emit('update', value)
    },
  },
}
