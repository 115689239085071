import { errorFromResponse, notifications } from '@/app/notifications'
import {
  getters,
  actions as parentActions,
  mutations as parentMutations,
  state as parentState,
} from '~/sites/soh/store/cart'
import { hookDefinitions, hookDispatch } from '~/hooks'
import eventBus from '@/events/eventBus'
import eventDefinitions from '@/events/eventDefinitions'

const actions = {
  ...parentActions,
  async PLACE_ORDER(actionContext) {
    const { commit, state, dispatch, rootState } = actionContext
    try {
      const name = this.$themeSettings.global.userFullName
        ? state.input.fullName
        : state.input.firstName + ' ' + state.input.lastName
      const getDefaultAddress = key => {
        const searchKey = key !== 'country_code' ? key : 'countryCode'
        return state.input.shipperBranch
          ? state.input.shipperBranch.address[searchKey]
          : rootState.globals.contact.address[searchKey]
      }
      const setShipperAddress = addressData => {
        for (const val of ['street', 'city', 'postcode', 'country_code']) {
          addressData[val] = getDefaultAddress(val)
        }
      }
      //create text from dates that is then saved into order note
      let startDate = state.renting.startDate
      let endDate = state.renting.endDate
      let printStartDate = ''
      let printEndDate = ''
      let datesString = ''

      if (typeof startDate == 'string' && startDate.length > 0) {
        let parts = startDate.split('T')[0].split('-')
        printStartDate = parts[2] + '/' + parts[1] + '/' + parts[0]
      }
      if (typeof endDate == 'string' && endDate.length > 0) {
        let parts = endDate.split('T')[0].split('-')
        printEndDate = parts[2] + '/' + parts[1] + '/' + parts[0]
      }

      if (typeof startDate == 'number') {
        startDate = new Date(startDate)
      }
      if (typeof endDate == 'number') {
        endDate = new Date(endDate)
      }

      if (startDate instanceof Date) {
        printStartDate =
          String(startDate.getUTCDate()) +
          '/' +
          String(startDate.getUTCMonth() + 1) +
          '/' +
          String(startDate.getUTCFullYear())
      }
      if (endDate instanceof Date) {
        printEndDate =
          String(endDate.getUTCDate()) +
          '/' +
          String(endDate.getUTCMonth() + 1) +
          '/' +
          String(endDate.getUTCFullYear())
      }
      datesString = 'OD: ' + printStartDate + '\n' + 'DO: ' + printEndDate + '\n'
      if (state.input.note.length > 0) {
        datesString += '---\npoznámka zákazníka:\n'
      }
      commit('RESET_RENT_DATES')
      const data = {
        name,
        firstName: state.input.firstName,
        lastName: state.input.lastName,
        email: state.input.email,
        phone: state.input.phone,
        billing_address: {
          street: state.input.billingHouseNumber
            ? `${state.input.billingStreet} ${state.input.billingHouseNumber}`
            : state.input.billingStreet,
          city: state.input.billingCity,
          postcode: state.input.billingPostcode,
          country_code: state.input.billingCountry,
        },
        business: state.input.isBusiness
          ? {
              business_name: state.input.businessName,
              TIN: state.input.tin,
              VATIN: state.input.vatin,
            }
          : null,
        shipping: state.input.shipToShippingAddress
          ? {
              name,
              address: {
                street: state.input.houseNumber
                  ? `${state.input.street} ${state.input.houseNumber}`
                  : state.input.street,
                city: state.input.city,
                postcode: state.input.postcode,
                country_code: state.input.country,
              },
            }
          : {
              name,
              address: {
                street: state.input.billingHouseNumber
                  ? `${state.input.billingStreet} ${state.input.billingHouseNumber}`
                  : state.input.billingStreet,
                city: state.input.billingCity,
                postcode: state.input.billingPostcode,
                country_code: state.input.billingCountry,
              },
            },
        custom: state.input.custom,
        comment: datesString + state.input.note,
        payment_id: state.input.payment ? state.input.payment.id : null,
        shipper_id: state.input.shipper ? state.input.shipper.id : null,
        branch_id: state.input.shipperBranch ? state.input.shipperBranch.id : null,
        terms: true,
        cardRequest: state.input.cardRequest,
        order_gifts: state.selectedGifts.map(g => g.id),
        newsletter: state.input.newsletter,
        heureka_allow: state.input.complianceHeureka,
        phone_notification: state.input.complianceSmsMarketing,
        delivery_time_id: state.input.deliveryTimeSlot ? state.input.deliveryTimeSlot.id : null,
        ...(state.input.registration && state.input.password ? { password: state.input.password } : {}),
        ...(state.input.registration ? { registration: state.input.registration } : {}),
      }
      if (state.input.shippingBusinessName && state.input.shippingBusinessName.replace(/\s/g, '').length > 0) {
        data.shipping.business_name = state.input.shippingBusinessName
      }
      if (
        state.input.shipToShippingAddress &&
        state.input.shippingPhone &&
        state.input.shippingPhone.replace(/\s/g, '').length > 0
      ) {
        data.shipping.phone = state.input.shippingPhone
      }
      if (state.input.formalTitle) {
        data.custom.formalTitle = state.input.formalTitle
      }
      if (state.input.vatinsvk) {
        data.custom.ICDPH = state.input.vatinsvk
      }
      if (state.input.dateOfBirth) {
        data.custom.dateOfBirth = state.input.dateOfBirth
      }
      if (state.input.companyName) {
        data.custom.companyName = state.input.companyName
      }

      // Fill shipper branch address if custom billing address is disabled
      if (!state.input.billingInfo) {
        setShipperAddress(data.billing_address) // Set default billing address
        if (data.business) {
          data.business = null // Reset business data
        }
      }

      // Fill shipper branch address if address is not required
      if (state.input.shipper && state.input.shipper.requiredAddress === false) {
        setShipperAddress(data.shipping.address) // Set default shipping address
        if (data.shipping.business_name) {
          delete data.shipping.business_name // Remove shipping company name
        }
        if (data.shipping.phone) {
          delete data.shipping.phone // Remove shipping phone number
        }
      }
      console.log(data.comment)
      await hookDispatch(hookDefinitions.CART.PLACE_ORDER.DATA_PROCESS, { actionContext, data })
      const response = await this.$axios.$post(this.$env.STORE_URL + '/api/v1/cart/place', data)
      const orderData = await dispatch('UPDATE_DATA_WITH_VARIANTS', response.data)
      commit('SET_PLACED_ORDER', orderData)
      dispatch('RESET_CART_INPUT')
      commit('SET_EMPTY_CART')
      commit('SET_EMPTY_GIFTS')
      return response
    } catch (e) {
      console.log('PLACE_ORDER error:', e)
      return e.response
    }
  },
  async ADD_TO_CART({ commit, state, dispatch, store }, { id, type, quantity, parentId, newLine, forCredits, custom }) {
    try {
      const product = await dispatch('product/LOAD_PRODUCTS', { ids: [id] }, { root: true })
      let maxAmountInCart = product[0]?.content?.max_cart_quantity || 100
      let currentAmountInCart =
        state.cartData.items &&
        state.cartData.items.length > 0 &&
        state.cartData.items.filter(item => item.product.id === id)
          ? state.cartData.items.filter(item => item.product.id === id).length
          : 0

      if (currentAmountInCart < maxAmountInCart) {
        const response = await this.$axios.$post(this.$env.STORE_URL + '/api/v1/cart/items', {
          id: id,
          type: type || 'Inspishop\\Shop\\Model\\Entities\\Product',
          amount: Number(quantity),
          parent_id: parentId ? Number(parentId) : null,
          newLine: newLine || false,
          credits: forCredits || false,
          custom: custom || [],
        })
        await dispatch('SET_CART_DATA', response.data.cart)
        const addedItem = response.data.cart.items.find(cartItem => cartItem.id === response.data.addedItem.id)
        if (addedItem) {
          response.data.addedItem = addedItem
          eventBus.$emit(eventDefinitions.CART.ITEM.ADD, {
            addedAmount: response.data.changedAmount,
            item: addedItem,
          })
        }
        if (response.meta.custom && response.meta.custom['X-Inspishop-Guest-Cart']) {
          commit('SET_GUEST_CART_TOKEN', response.meta.custom['X-Inspishop-Guest-Cart'])
          dispatch('SET_AXIOS_GUEST_CART_TOKEN')
          return response.data
        }
      } else {
        notifications.info('Maximální množství tohoto produktu v košíku bylo dosaženo.')
        return false
      }
    } catch (e) {
      errorFromResponse(e.response)
    }
  },
}

const state = () => ({
  ...parentState(),
  renting: {
    startDate: new Date(),
    endDate: new Date(),
    rentDuration: 1,
  },
})

export const mutations = {
  ...parentMutations,
  SET_RENT_DURATION(state, duration) {
    state.renting.rentDuration = duration
  },
  SET_RENT_DATES(state, selectedDate) {
    state.renting.startDate = selectedDate.start
    state.renting.endDate = selectedDate.end
  },
  RESET_RENT_DATES(state) {
    state.renting.startDate = Date.now()
    state.renting.endDate = Date.now()
    state.renting.rentDuration = 1
  },
}

export default { actions, getters, mutations, state }
