import CartInfo from '~/themes/base/components/cart/CartInfo'

export default {
  mixins: [CartInfo],
  computed: {
    itemsCount: function() {
      const cartItems = this.$store.state.cart.cartData.items
      if (cartItems) {
        return this.$store.state.cart.cartData.items.length
      } else {
        return 0
      }
    },
  },
}
