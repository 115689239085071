import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0ace6b43 = () => interopDefault(import('../sites/rent/pages/user/activate.vue' /* webpackChunkName: "sites/rent/pages/user/activate" */))
const _2d865043 = () => interopDefault(import('../sites/rent/pages/articleList/index.vue' /* webpackChunkName: "sites/rent/pages/articleList/index" */))
const _662563de = () => interopDefault(import('../sites/rent/pages/index.vue' /* webpackChunkName: "sites/rent/pages/index" */))
const _08734ecf = () => interopDefault(import('../sites/rent/pages/search/index.vue' /* webpackChunkName: "sites/rent/pages/search/index" */))
const _86fa1ea2 = () => interopDefault(import('../sites/rent/pages/contact/index.vue' /* webpackChunkName: "sites/rent/pages/contact/index" */))
const _6318f5ed = () => interopDefault(import('../sites/rent/pages/checkout/index.vue' /* webpackChunkName: "sites/rent/pages/checkout/index" */))
const _2fcd06f5 = () => interopDefault(import('../sites/rent/pages/login.vue' /* webpackChunkName: "sites/rent/pages/login" */))
const _628db1c7 = () => interopDefault(import('../sites/rent/pages/register.vue' /* webpackChunkName: "sites/rent/pages/register" */))
const _786b7d00 = () => interopDefault(import('../sites/rent/pages/passwordReset.vue' /* webpackChunkName: "sites/rent/pages/passwordReset" */))
const _9f813e7a = () => interopDefault(import('../sites/rent/pages/sitemapconfig.vue' /* webpackChunkName: "sites/rent/pages/sitemapconfig" */))
const _2f163e9f = () => interopDefault(import('../sites/rent/pages/maintenance.vue' /* webpackChunkName: "sites/rent/pages/maintenance" */))
const _9deb4f9c = () => interopDefault(import('../sites/rent/pages/user/index.vue' /* webpackChunkName: "sites/rent/pages/user/index" */))
const _4c010f6a = () => interopDefault(import('../sites/rent/pages/passwordForgotten.vue' /* webpackChunkName: "sites/rent/pages/passwordForgotten" */))
const _12f77f3a = () => interopDefault(import('../sites/rent/pages/checkout/info.vue' /* webpackChunkName: "sites/rent/pages/checkout/info" */))
const _6e5e78b2 = () => interopDefault(import('../sites/rent/pages/checkout/finished.vue' /* webpackChunkName: "sites/rent/pages/checkout/finished" */))
const _8d4257e6 = () => interopDefault(import('../sites/rent/pages/checkout/methods.vue' /* webpackChunkName: "sites/rent/pages/checkout/methods" */))
const _b97e9cf2 = () => interopDefault(import('../sites/rent/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/rent/pages/checkout/importOrder/index" */))
const _5e2cd5b0 = () => interopDefault(import('../sites/rent/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/rent/pages/user/shoppingLists/index" */))
const _d47c2e10 = () => interopDefault(import('../sites/rent/pages/user/orders/index.vue' /* webpackChunkName: "sites/rent/pages/user/orders/index" */))
const _71de3a73 = () => interopDefault(import('../sites/rent/pages/user/settings.vue' /* webpackChunkName: "sites/rent/pages/user/settings" */))
const _72417618 = () => interopDefault(import('../sites/rent/pages/user/dashboard.vue' /* webpackChunkName: "sites/rent/pages/user/dashboard" */))
const _f2b71fdc = () => interopDefault(import('../sites/rent/pages/user/addressList.vue' /* webpackChunkName: "sites/rent/pages/user/addressList" */))
const _73d13ceb = () => interopDefault(import('../sites/rent/pages/user/password.vue' /* webpackChunkName: "sites/rent/pages/user/password" */))
const _1c9f0e63 = () => interopDefault(import('../sites/rent/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/rent/pages/checkout/payment/callback" */))
const _4fb46d40 = () => interopDefault(import('../sites/rent/pages/user/orders/_id.vue' /* webpackChunkName: "sites/rent/pages/user/orders/_id" */))
const _31548418 = () => interopDefault(import('../sites/rent/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/rent/pages/user/shoppingLists/_id" */))
const _e4ad3686 = () => interopDefault(import('../sites/rent/pages/article/_slug.vue' /* webpackChunkName: "sites/rent/pages/article/_slug" */))
const _2bcfaefb = () => interopDefault(import('../sites/rent/pages/articleList/_slug.vue' /* webpackChunkName: "sites/rent/pages/articleList/_slug" */))
const _008e88ae = () => interopDefault(import('../sites/rent/pages/page/_slug.vue' /* webpackChunkName: "sites/rent/pages/page/_slug" */))
const _565d8176 = () => interopDefault(import('../sites/rent/pages/product/_slug.vue' /* webpackChunkName: "sites/rent/pages/product/_slug" */))
const _0e4c86eb = () => interopDefault(import('../sites/rent/pages/_.vue' /* webpackChunkName: "sites/rent/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivovat",
    component: _0ace6b43,
    name: "user-activate___cs"
  }, {
    path: "/clanky",
    component: _2d865043,
    name: "articleList___cs"
  }, {
    path: "/en",
    component: _662563de,
    name: "index___en"
  }, {
    path: "/hledat",
    component: _08734ecf,
    name: "search___cs"
  }, {
    path: "/kontakt",
    component: _86fa1ea2,
    name: "contact___cs"
  }, {
    path: "/kosik",
    component: _6318f5ed,
    name: "checkout___cs"
  }, {
    path: "/prihlasit",
    component: _2fcd06f5,
    name: "login___cs"
  }, {
    path: "/registrovat",
    component: _628db1c7,
    name: "register___cs"
  }, {
    path: "/resetovat-heslo",
    component: _786b7d00,
    name: "passwordReset___cs"
  }, {
    path: "/sitemapconfig",
    component: _9f813e7a,
    name: "sitemapconfig___cs"
  }, {
    path: "/udrzba",
    component: _2f163e9f,
    name: "maintenance___cs"
  }, {
    path: "/uzivatel",
    component: _9deb4f9c,
    name: "user___cs"
  }, {
    path: "/zapomenute-heslo",
    component: _4c010f6a,
    name: "passwordForgotten___cs"
  }, {
    path: "/en/activate",
    component: _0ace6b43,
    name: "user-activate___en"
  }, {
    path: "/en/articles",
    component: _2d865043,
    name: "articleList___en"
  }, {
    path: "/en/cart",
    component: _6318f5ed,
    name: "checkout___en"
  }, {
    path: "/en/contact",
    component: _86fa1ea2,
    name: "contact___en"
  }, {
    path: "/en/forgotten-password",
    component: _4c010f6a,
    name: "passwordForgotten___en"
  }, {
    path: "/en/login",
    component: _2fcd06f5,
    name: "login___en"
  }, {
    path: "/en/maintenance",
    component: _2f163e9f,
    name: "maintenance___en"
  }, {
    path: "/en/register",
    component: _628db1c7,
    name: "register___en"
  }, {
    path: "/en/reset-password",
    component: _786b7d00,
    name: "passwordReset___en"
  }, {
    path: "/en/search",
    component: _08734ecf,
    name: "search___en"
  }, {
    path: "/en/sitemapconfig",
    component: _9f813e7a,
    name: "sitemapconfig___en"
  }, {
    path: "/en/user",
    component: _9deb4f9c,
    name: "user___en"
  }, {
    path: "/kosik/dodaci-udaje",
    component: _12f77f3a,
    name: "checkout-info___cs"
  }, {
    path: "/kosik/dokonceni",
    component: _6e5e78b2,
    name: "checkout-finished___cs"
  }, {
    path: "/kosik/doprava-a-platba",
    component: _8d4257e6,
    name: "checkout-methods___cs"
  }, {
    path: "/kosik/import-objednavky",
    component: _b97e9cf2,
    name: "checkout-importOrder___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy",
    component: _5e2cd5b0,
    name: "user-shoppingLists___cs"
  }, {
    path: "/uzivatel/objednavky",
    component: _d47c2e10,
    name: "user-orders___cs"
  }, {
    path: "/uzivatel/osobni-udaje",
    component: _71de3a73,
    name: "user-settings___cs"
  }, {
    path: "/uzivatel/prehled",
    component: _72417618,
    name: "user-dashboard___cs"
  }, {
    path: "/uzivatel/seznam-adres",
    component: _f2b71fdc,
    name: "user-addressList___cs"
  }, {
    path: "/uzivatel/zmena-hesla",
    component: _73d13ceb,
    name: "user-password___cs"
  }, {
    path: "/checkout/payment/callback",
    component: _1c9f0e63,
    name: "checkout-payment-callback___cs"
  }, {
    path: "/en/cart/delivery-details",
    component: _12f77f3a,
    name: "checkout-info___en"
  }, {
    path: "/en/cart/finish",
    component: _6e5e78b2,
    name: "checkout-finished___en"
  }, {
    path: "/en/cart/import-order",
    component: _b97e9cf2,
    name: "checkout-importOrder___en"
  }, {
    path: "/en/cart/shipping-and-payment",
    component: _8d4257e6,
    name: "checkout-methods___en"
  }, {
    path: "/en/user/address-list",
    component: _f2b71fdc,
    name: "user-addressList___en"
  }, {
    path: "/en/user/change-password",
    component: _73d13ceb,
    name: "user-password___en"
  }, {
    path: "/en/user/orders",
    component: _d47c2e10,
    name: "user-orders___en"
  }, {
    path: "/en/user/overview",
    component: _72417618,
    name: "user-dashboard___en"
  }, {
    path: "/en/user/settings",
    component: _71de3a73,
    name: "user-settings___en"
  }, {
    path: "/en/user/shopping-lists",
    component: _5e2cd5b0,
    name: "user-shoppingLists___en"
  }, {
    path: "/en/checkout/payment/callback",
    component: _1c9f0e63,
    name: "checkout-payment-callback___en"
  }, {
    path: "/",
    component: _662563de,
    name: "index___cs"
  }, {
    path: "/en/user/orders/:id",
    component: _4fb46d40,
    name: "user-orders-id___en"
  }, {
    path: "/en/user/shopping-lists/:id",
    component: _31548418,
    name: "user-shoppingLists-id___en"
  }, {
    path: "/en/article/:slug",
    component: _e4ad3686,
    name: "article-slug___en"
  }, {
    path: "/en/articles/:slug",
    component: _2bcfaefb,
    name: "articleList-slug___en"
  }, {
    path: "/en/page/:slug",
    component: _008e88ae,
    name: "page-slug___en"
  }, {
    path: "/en/product/:slug",
    component: _565d8176,
    name: "product-slug___en"
  }, {
    path: "/uzivatel/nakupni-seznamy/:id",
    component: _31548418,
    name: "user-shoppingLists-id___cs"
  }, {
    path: "/uzivatel/objednavky/:id",
    component: _4fb46d40,
    name: "user-orders-id___cs"
  }, {
    path: "/clanek/:slug",
    component: _e4ad3686,
    name: "article-slug___cs"
  }, {
    path: "/clanky/:slug",
    component: _2bcfaefb,
    name: "articleList-slug___cs"
  }, {
    path: "/produkt/:slug",
    component: _565d8176,
    name: "product-slug___cs"
  }, {
    path: "/stranka/:slug",
    component: _008e88ae,
    name: "page-slug___cs"
  }, {
    path: "/en/*",
    component: _0e4c86eb,
    name: "all___en"
  }, {
    path: "/*",
    component: _0e4c86eb,
    name: "all___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
