import { merge } from 'lodash'
import themeSettings from '~/themes/soh/settings'

const overloadSettings = {
  global: {
    showAvailability: false,
  },
  components: {
    AddToCartModalItemAdded: {
      showChangedAmount: false,
    },
    CartInfoDropDownContent: {
      showDeliveryDate: false,
    },
    ContactBox: {
      alignContent: 'center',
    },
    CheckoutItemLine: {
      showPercentageDiscount: true,
    },
    CheckoutMethodsShipper: {
      showDeliveryDate: false,
    },
    CurrencySwitcher: {
      dropdown: true,
      enable: true,
    },
    MainMenu: {
      showDropdownIndicator: false,
    },
    LanguageSwitcher: {
      enable: true,
      dropdown: false,
      showActiveLanguage: true,
    },
    PageHomepage: {
      mainHeadingPosition: 'belowSlider', // aboveSlider, belowSlider
      showHomepageBranches: false,
      showHomepageTextBlock: true,
      showProductsRecommended: true,
      showUniqueSellingPoints: false,
    },
    PageHomepageMainHeading: {
      enable: false,
    },
    PageProductDetail: {
      showAvailability: false,
      showDeliveryOptions: false,
      showAvailabilityInWarehouses: false,
    },
    ProductBox: {
      showAvailability: false,
    },
    ProductTeaser: {
      showAvailability: false,
    },
    Slider: {
      cmsTextContent: {
        enable: true,
      },
    },
  },
}

export default merge(themeSettings, overloadSettings)
